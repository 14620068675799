<template>
  <v-autocomplete
    ref="facilityAutocomplete"
    v-model="model"
    solo
    flat
    clearable
    :items="allFacilities"
    item-text="Name"
    item-value="id"
    label="Select a Facility"
    hide-details
    prepend-inner-icon="mdi-magnify"
    placeholder="Start typing to search facilities..."
    @change="id => $emit('setFacility', id)"
  >
    <template v-slot:item="data">
      <v-list-item @click="selectFacility(data.item)">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon v-if="data.item.archived && showArchived" class="ml-2">mdi-archive</v-icon>
            {{ data.item.Name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'FacilitySearch',
  props: ['allFacilities', 'showArchived'],
  data: () => ({
    model: null,
  }),
  watch: {
    allFacilities(newVal) {
      if (newVal.length === 1) {
        this.model = newVal[0].id;
        this.$store.commit('changeActiveFacility', newVal[0].id);
        this.$emit('setFacility', newVal[0].id);
      }
    },
  },
  methods: {
    selectFacility(item) {
      this.model = item.id;
      this.$emit('setFacility', item.id);
      this.$nextTick(() => {
        this.$refs.facilityAutocomplete.blur(); // Close the autocomplete after selection
      });
    },
  },
};
</script>
